import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

export const SET_LISTA_TRIAGEM = "setListTriagem"
export const SET_MESSAGE_ALERT = "setMensagem"
export const SET_MESSAGE_ALERT2 = "setMensagem2"
export const SET_OCORRENCIA = "setOcorrencia"
export const SET_LISTA_OCORRENCIAS_ATENDIMENTO = "setOcorrenciasAtendimento"
export const SET_LISTA_GRAU_URGENCIA = "setListGrauUrgencia"
export const SET_LISTA_TIPO_OCORRENCIA = "setListTipoOcorrencia"
export const SET_DIAGNOSTICO = "SetDiagnostico"
export const SET_DADOS = "SetDados"
export const SET_LISTA_ARQUIVOS = "SetListaArquivos"
export const SET_LINK_IMG = "SetLinkImg"
export const SET_LISTA_RECEITA = "SetListaReceita"

const actions = {
    async create_triagem(context, value) {
        let data = await ApiService.post('ocorrencia/insert_triagem', value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT2, data)
        context.commit(SET_OCORRENCIA, data.message.conteudo)
    },

    async create_consulta(context, value) {
        let data = await ApiService.post('ocorrencia/insert_consulta', value,)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT2, data)
        context.commit(SET_OCORRENCIA, data.message.conteudo)
    },

    async create_internacao(context, value) {
        let data = await ApiService.post('ocorrencia/insert_internacao', value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT, data)
        context.commit(SET_OCORRENCIA, data.message.conteudo)
    },

    async create_receita(context, value) {
        let data = await ApiService.post('ocorrencia/insert_receita', value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT, data)
        context.commit(SET_OCORRENCIA, data.message.conteudo)
    },
    async update_triagem(context, value) {
        let message = await ApiService.put('ocorrencia/triagem', value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT, message)
    },
    async update_consulta(context, value) {
        let message = await ApiService.put('ocorrencia/consulta', value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT, message)
    },
    async update_receita(context, value) {
        let message = await ApiService.put('ocorrencia/receita', value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT, message)
    },
    async update_internacao(context, value) {
        let message = await ApiService.put('ocorrencia/internacao', value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT, message)
    },

    async listar_receitas(context, value) {
        await ApiService.post('receita/search', value)
            .then(response => context.commit(SET_LISTA_RECEITA, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    async listar_tipo_ocorrencia(context) {
        await ApiService.get('tipo_ocorrencia')
            .then(response => context.commit(SET_LISTA_TIPO_OCORRENCIA, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    async listar_grau_urgencia(context) {
        await ApiService.get('grau_urgencia')
            .then(response => context.commit(SET_LISTA_GRAU_URGENCIA, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },


    async getDiagnosticoId(context, value) {
        await ApiService.get('diagnostico/' + value)
            .then(response => context.commit(SET_DIAGNOSTICO, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    async save_dados_modais(context, value) {
        await context.commit(SET_DADOS, value);
    },

    async listar_arquivos_consulta(context, value) {
        await ApiService.get('ocorrencia/arquivos/' + value)
            .then(response => context.commit(SET_LISTA_ARQUIVOS, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },

    async downloadArquivos(context, value) {
        //o metodo dever ser get para funcionar
        await ApiService.get('ocorrencia/downloadArquivo/' + value.id, {
            responseType: 'blob'
        }).then(response => {
            console.log(response)
            console.log(value.file.name)
            const fileUrl = window.URL.createObjectURL(new Blob([response.data]))//montamos uma url com a resposta
            let fileLink = document.createElement('a')// cria um link com a tag a
            fileLink.href = fileUrl // coloca o link no href da tag a criada
            fileLink.setAttribute('download', value.file.name) // da um nome para o arquivo que sera baixado do link
            document.body.appendChild(fileLink)//insere o elemento dentro do body
            fileLink.click()// força um click no elemento para o dowload de fato

        }).catch((error) => context.commit(SET_MESSAGE_ALERT, {
            tipo: 'error', message: error.response.data
        }))
    },

    async visualizarArquivos(context, value) {
        //o metodo dever ser get para funcionar
        await ApiService.get('ocorrencia/downloadArquivo/' + value.id, {
            responseType: 'blob'
        }).then(response => {
            console.log(response)
            console.log(value.file.name)
            const fileUrl = window.URL.createObjectURL(new Blob([response.data]))//montamos uma url com a resposta
            // console.log(fileUrl)

            context.commit(SET_LINK_IMG, fileUrl)
            // let fileLink = document.createElement('img')// cria um link com a tag a
            // fileLink.href = fileUrl // coloca o link no href da tag a criada
            // fileLink.setAttribute('download', value.file.name) // da um nome para o arquivo que sera baixado do link
            // document.body.appendChild(fileLink)//insere o elemento dentro do body
            // fileLink.click()// força um click no elemento para o dowload de fato

        }).catch((error) => context.commit(SET_MESSAGE_ALERT, {
            tipo: 'error', message: error.response.data
        }))
    },

    async pdf_ticket(context, ocorrencia_id) {
        ApiService.get("saida_produto/ticket/" + ocorrencia_id, {
            responseType: 'blob'
        }).then(response => {
            let fileUrl = window.URL.createObjectURL(response.data);
            let fileLink = document.createElement('a');

            fileLink.href = fileUrl;
            // fileLink.setAttribute('download', 'ticket.pdf');
            fileLink.target = '_blank';
            document.body.appendChild(fileLink)
            fileLink.click();
        }).catch((error) => context.commit(SET_MESSAGE_ALERT, {
            tipo: 'error', message: error.response.data
        }))
    },
    
    async dowload_ticket(context, ocorrencia_id) {
        ApiService.get("saida_produto/ticket/" + ocorrencia_id, {
            responseType: 'blob'
        }).then(response => {
            let fileUrl = window.URL.createObjectURL(response.data);
            let fileLink = document.createElement('a');

            fileLink.href = fileUrl;
            fileLink.setAttribute('download', 'ticket.pdf');
            // fileLink.target = '_blank';
            document.body.appendChild(fileLink)
            fileLink.click();
        }).catch((error) => context.commit(SET_MESSAGE_ALERT, {
            tipo: 'error', message: error.response.data
        }))
    },

};

const getters = {};

const mutations = {
    [SET_DADOS](state, value) {
        var d = {
            tipo: value.tipo,
            fechar: value.fechar,
        }
        state.dados_modais = d

    },
    [SET_LINK_IMG](state, value) {
        state.link_img = value
    },
    [SET_LISTA_TRIAGEM](state, value) {
        state.lista_atendimentos = value
    },
    [SET_OCORRENCIA](state, value) {
        state.ocorrencia = value
    },
    [SET_LISTA_RECEITA](state, value) {
        state.lista_receitas = value
    },
    [SET_LISTA_GRAU_URGENCIA](state, value) {
        state.lista_grau_urgencia = value
    },
    [SET_LISTA_TIPO_OCORRENCIA](state, value) {
        state.lista_tipo_ocorrencia = value
    },
    [SET_LISTA_ARQUIVOS](state, value) {
        state.lista_arquivos_consulta = value
    },
    [SET_DIAGNOSTICO](state, value) {
        state.dados_diagnostico = value
    },

    [SET_MESSAGE_ALERT2](state, value) {
        let customMessage;
        if (typeof value.message === "object") {
            customMessage = value.message.msg;
        }
        state.mensagem_alert = {
            tipo: value.tipo,
            message: customMessage ? customMessage : value.message,
        };
    },

    [SET_MESSAGE_ALERT](state, value) {

        console.log(value)
        let customMessage;
        if (typeof value.message === "object") {

            customMessage = value.message.msg;
        }
        state.mensagem_alert = {
            tipo: value.tipo,
            message: customMessage ? customMessage : value.message,
        };
    },

};

const state = {
    lista_atendimentos: [],
    mensagem_alert: "",
    lista_tipo_ocorrencia: [],
    lista_grau_urgencia: [],
    lista_ocorrenicas_atendimento: '',
    ocorrencia: [],
    dados_diagnostico: [],
    lista_arquivos_consulta: [],
    dados_modais: {
        tipo: "",
        fechar: false,
    },
    link_img: "",
    lista_receitas: []
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};