// Spain
export const locale = {
  TRANSLATOR: {
    SELECT: "Elige tu idioma"
  },
  MENU: {
    NEW: "Nuevo",
    ACTIONS: "Comportamiento",
    CREATE_POST: "Crear nueva publicación",
    PAGES: "Pages",
    FEATURES: "Caracteristicas",
    APPS: "Aplicaciones",
    DASHBOARD: "Tablero"
  },
  AUTH: {
    GENERAL: {
      OR: "O",
      SUBMIT_BUTTON: "Enviar",
      NO_ACCOUNT: "No tienes una cuenta?",
      SIGNUP_BUTTON: "Regístrate",
      FORGOT_BUTTON: "Se te olvidó tu contraseña",
      BACK_BUTTON: "Espalda",
      PRIVACY: "Intimidad",
      LEGAL: "Legal",
      CONTACT: "Contacto",
      TROCAR:"Cambiar contraseña"
    },
    LOGIN: {
      TITLE: "Crear una cuenta",
      BUTTON: "Registrarse"
    },
    FORGOT: {
      TITLE: "Contraseña olvidada?",
      DESC: "Ingrese su correo electrónico para restablecer su contraseña",
      SUCCESS: "Su cuenta se ha restablecido con éxito."
    },
    REGISTER: {
      TITLE: "Registrarse",
      DESC: "Introduce tus datos para crear tu cuenta",
      SUCCESS: "Su cuenta ha sido registrada exitosamente.",
      DSENHA:"Ingrese la nueva contraseña",
      CSENHA:"Confirme la contraseña",
      DSENHAATUAL:"Ingrese su contraseña actual"
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Nombre Cpmpleto",
      PASSWORD: "Contraseña",
      CONFIRM_PASSWORD: "Confirmar Contraseña",
      USERNAME: "Usuario"
    },
    VALIDATION: {
      INVALID: "{{name}} no es válido",
      REQUIRED: "{{name}} es necesario",
      MIN_LENGTH: "{{name}} la longitud mínima es {{min}}",
      AGREEMENT_REQUIRED: "Se requiere aceptar los términos y condiciones",
      NOT_FOUND: "{{name}} no fue encontrado",
      INVALID_LOGIN: "El detalle de inicio de sesión es incorrecto",
      REQUIRED_FIELD: "Campo requerido",
      MIN_LENGTH_FIELD: "Longitud mínima del campo:",
      MAX_LENGTH_FIELD: "Longitud máxima del campo:",
      INVALID_FIELD: "El campo no es válido"
    }
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Selected records count: ",
      ALL: "All",
      SUSPENDED: "Suspendido",
      ACTIVE: "Activo",
      FILTER: "Filter",
      BY_STATUS: "by Status",
      BY_TYPE: "by Type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: "in all fields"
    },
    ECOMMERCE: "eCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Pacientes",
      CUSTOMERS_LIST: "Lista pacientes",
      NEW_CUSTOMER: "Nuevo paciente",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Eliminar paciente",
        DESCRIPTION: "Está seguro de eliminar permanentemente a este paciente?",
        WAIT_DESCRIPTION: "Se está eliminando",
        MESSAGE: "H sido eliminado"
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Eliminar pacientes",
        DESCRIPTION: "¿Está seguro de eliminar permanentemente los pacientes seleccionados?",
        WAIT_DESCRIPTION: "Estan siendo eliminados...",
        MESSAGE: "Los ítens seleccionados han sido eliminados"
      },
      UPDATE_STATUS: {
        TITLE: "Se ha actualizado el estado de los pacientes seleccionados",
        MESSAGE: "El estado de los pacientes seleccionados se ha actualizado correctamente"
      },
      EDIT: {
        UPDATE_MESSAGE: "Ha sido actualizado",
        ADD_MESSAGE: "Ha sido creado"
      }
    }
  },
  FUNCIONARIO:{
    DASHBOARD:"Aquí puedes gerenciar los funcionarios"
  },
  PACIENTES:{
    DASHBOARD:"Aquí puedes gerenciar los pacientes"
  },
  ESTOQUES:{
    DASHBOARD:"Aquí puedes gerenciar los estoques",
    ATIVOS:"Estoques",
    VALIDADE:"Validez",
    INICIOVALIDADE:"Fecha inicio de Validez",
    FIMVALIDADE:"Fecha final de Validez",
  },
  ATENDIMENTO:{
    NOME:"Atendimiento",
    DASHBOARD:"Aquí puedes gerenciar los atendimientos"
  },
  GERENCIAR:{
    CRIAR:"Crear",
    NOME:"Guardar",
    
  },
  LABORATORIO:{
    NOVO_LAB:"Nuevo Laboratorio",
  },
  PRODUTOS:{
    PRODUTO:"Productos",
    NOVO_PRODUTO:"Nuevo Producto",
    ESTOQUE:{
      
      descricao_valor_total:"Acá queda el valor total pago en el estoque cerrado",
      descricao_valor_unitario:"Este valor es calculado automáticamente despues que ya haya valor total y cantidad total llenados"
    }
  },
  RELATORIO:{
    FILIAL:"MAS REPORTES",
    RELATORIOS:"REPORTES"
  },
  SUBSTANCIA:{
    NOVA_SUBSTANCIA:"Nueva Substancia",
  },
  TIPOPRODUTO:{
    NOME:"Tipo de Producto",
  },
  TIPO:{
    NOVO_TIPO:"Nuevo Tipo",
  },
  LOCAL:{
    NOVO_LOCAL:"Nuevo Local",
  },
  SETOR:{
    NOVO_SETOR:"Nuevo Sector",
  },
  PERFIL:{
    NOVO_PERFIL:"Novo Perfil",
  },
  SALA:{
    NOVA_SALA:"Nueva Sala",
  },
  VINCULO:{
    NOVO_VINCULO:"Nuevo Vinculo",
  },
  SALVAR:{
    NOME:"Guardar",
  },
  GUARDARDATOS:{
    NOME:"Guardar",
  },
  ATIVO:{
    NOME:"Activo",
  },
  DESATIVADO:{
    NOME:"Desactivado",
  },
  SUSPENSO:{
    NOME:"Suspendido",
  },
  DESCRICAO:{
    NOME:"Descripción",
    ENDERECO:"Dirección",
    CONTATO:"Contacto",
  },
  ESTADO:{
    NOME:"Estado:",
  },
  DESCRIBIR:{
    NOME:"Escribe la descripción...",
  },
  BAIRRO:{
    NOME:"Barrio:",
  },
  ENDERECO:{
    NOME:"Dirección:",
  },
  CIDADE:{
    NOME:"Ciudad:",
  },
  ESTADO2:{
    NOME:"Departamento:",
  },
  NATURALIDADE:{
    NOME:"Nacionalidad:",
  },
  NOMBRE:{
    NOME:"Nombre:",
  },
  TELEFONO1:{
    NOME:"Teléfono 1:",
  },
  TELEFONO2:{
    NOME:"Teléfono 2:",
  },
  NUMERO:{
    NOME:"Número:",
  },
  SOBRENOME:{
    NOME:"Apellido:",
  },
  DATA:{
    NOME:"Fecha de Nacimiento:",
    IDADE:"Edad:",
  },
  PROFESION:{
    NOME:"Profesión:",
  },
 
 // ATENDIMIENTOS PACIENTE

 DATAINICIO:{
  NOME:"Fecha de Inicio:",
},
DATAFINAL:{
  NOME:"Fecha Final:",
},
ATENCIONES:{
  NOME:"Atendimiento:",
},
FECHA:{
  NOME:"Fecha:",
},
OCORRENCIAS:{
  NOME:"Ocurrencias:",
},
TRIAGEM:{
  NOME:"Triaje",
},
RECEITA:{
  NOME:"Prescripción Medica",
},

// CONSULTA
MOTIVO_CONSULTA:{
  NOME:"Motivo de la Consulta (MC):*",
},
DESCRIP_CONSULTA:{
  NOME:"Escribe el motivo...",
},
DESCRIP_DATOS:{
  NOME:"Datos referidos por:*",
},
HDA:{
  NOME:" Antecedentes de la Enfermedad Actual (A.E.A):",
},
HRDA:{
  NOME:" Antecedentes Remotos de Enfermedad Actual (A.R.E.A):",
}, 
CONDUCTA:{
  NOME:"Conducta",
}, 
INTERVENCION:{
  NOME:" Antecedentes de Intervenciones quirúrgicas:",
}, 
EXAMENES:{
  NOME:" Examenes Fisicos:",
}, 

// TRIAJE
PRESSAO:{
  NOME:"Presión Arterial(mmHg)",
},
SATURACAO:{
  NOME:"Saturación(SpO2)",
},
FRE:{
  NOME:"Frecuencia Cardiaca(bpm)",
},
RESPIRATORIA:{
  NOME:"Frecuencia Respiratoria(bpm)",
},
URGENCIA:{
  NOME:"Grado de Urgencia:*",
},
ADMIN:{
  NOME:"Vía de Administración:*",
},
INSTRU:{
  NOME:"Instrucciones:*",
},

// DIAGNOSTICO
CREACION:{
  NOME:"Crear",
},
DIAG:{
  NOME:"Nombre del Diagnostico",
},
DES:{
  NOME:"Descripción",
},
ESCRIBIR:{
  NOME:"Descripción",
},
// SALIDA PRODUCTO
SALIDA:{
  NOME:"SaLida de ProduCto",
},

DADOSPRO:{
  NOME:"Datos de los Productos",
},
FRACCION:{
  NOME:"Fracción Filial:*",
},
QUANTIDADE:{
  NOME:"Cantidad Filial:*",
},
FRACAO:{
  NOME:"Fracción General:*",
},

// MODAL TRANSFERENCIA
TRANSFERENCIA:{
  TOTAL:"Cantidad total de itens transferidos"
},
DATATRANSF:{
  NOME:"Fecha de transferencia:*",
},
CANTI:{
  NOME:"Cantidad:*",
},
AFRACCION:{
  NOME:"Fracción:*",
},





};
